// Scripts associated with CTA grids and their items
document.addEventListener("DOMContentLoaded", function () {
    // Grab the heights of items and set them to be equal
    function setItemHeight() {
        let mheight = 0;
        let oheight = 0;

        const mCtaGridItems = Array.from(
            document.querySelectorAll(".m-ctaGrid__item--small")
        );
        mCtaGridItems.forEach((mCtaGridItem) => {
            if (mCtaGridItem.offsetHeight > mheight) {
                mheight = mCtaGridItem.offsetHeight;
            }
        });

        const oCtaGridItems = Array.from(
            document.querySelectorAll(".o-ctaGrid__item--small")
        );
        oCtaGridItems.forEach((oCtaGridItem) => {
            if (oCtaGridItem.offsetHeight > mheight) {
                oheight = oCtaGridItem.offsetHeight;
            }
        });

        // Sets those heights to be equal
        mCtaGridItems.forEach((mCtaGridItem) => {
            mCtaGridItem.style.height = mheight + "px";
        });
        oCtaGridItems.forEach((oCtaGridItem) => {
            oCtaGridItem.style.height = oheight + "px";
        });
    }

    // For large cta items, sets the height equal to the width
    function setCtaHeight() {
        let width = 0;
        let height = 0;

        const mCtaGridItems = Array.from(
            document.querySelectorAll(".m-ctaGridItem--large")
        );
        const oCtaGridItems = Array.from(
            document.querySelectorAll(".o-ctaGrid__item--large")
        );

        mCtaGridItems.forEach((mCtaGridItem) => {
            if (mCtaGridItem.offsetHeight > height) {
                height = mCtaGridItem.offsetHeight;
            }
            if (mCtaGridItem.offsetWidth > width) {
                width = mCtaGridItem.offsetWidth;
            }
            if (height < width) {
                height = width;
            }
        });

        mCtaGridItems.forEach((mCtaGridItem) => {
            mCtaGridItem.style.height = height + "px";
        });
        oCtaGridItems.forEach((oCtaGridItem) => {
            oCtaGridItem.style.height = height + "px";
        });
    }

    setItemHeight();
    setCtaHeight();
    window.addEventListener("resize", () => {
        setItemHeight();
        setCtaHeight();
    });
});
