// Primary navigation scripts
document.addEventListener("DOMContentLoaded", function () {
    //Preload removal and initial hiding
    const mobileMenus = Array.from(document.querySelectorAll(".o-mobileMenu"));
    const mobileMenuBlocks = Array.from(
        document.querySelectorAll(".o-mobileMenu__block")
    );
    const navBlocks = Array.from(document.querySelectorAll(".m-navBlock"));
    const navMenuLabels = Array.from(
        document.querySelectorAll(".m-navMenu__label")
    );
    const countrySelector = document.querySelector(".m-countrySelector");
    const searchForm = document.querySelector(".m-searchForm");
    const mobileNavToggle = document.querySelector("#mobile-navigation-toggle");
    const mobileSearchToggle = document.querySelector("#mobile-search-toggle");
    const mobileCountryToggle = document.querySelector(
        "#mobile-country-toggle"
    );
    const navSearchToggle = document.querySelector("#nav-search-toggle");
    const navCountryToggle = document.querySelector("#nav-country-toggle");
    const primaryNav = document.querySelector('.o-primaryNav');

    if (mobileMenus.length > 0) {
        mobileMenus.forEach((mobileMenu) => {
            mobileMenu.style.display = "none";
            mobileMenu.classList.add("o-mobileMenu--has-loaded");
            mobileMenu.classList.remove("o-mobileMenu--preload");
        });
    }

    if (mobileMenuBlocks.length > 0) {
        mobileMenuBlocks.forEach((mobileMenuBlock) => {
            mobileMenuBlock.style.display = "none";
            mobileMenuBlock.classList.add("o-mobileMenu__block--has-loaded");
            mobileMenuBlock.classList.remove("o-mobileMenu__block--preload");
        });
    }

    if (navBlocks.length > 0) {
        navBlocks.forEach((navBlock) => {
            navBlock.style.display = "none";
            navBlock.classList.add("m-navBlock--has-loaded");
            navBlock.classList.remove("m-navBlock--preload");
        });
    }

    if (navMenuLabels.length > 0) {
        navMenuLabels.forEach((navMenuLabel) => {
            navMenuLabel.classList.add("m-navMenu__label--has-loaded");

            navMenuLabel.addEventListener("click", () => {
                // If the menu is open, close it
                if (navMenuLabel.classList.contains("--active")) {
                    navMenuLabel.nextElementSibling.style.display = "none";
                    const activeNavMenuLabel = document.querySelector(
                        ".m-navMenu__label.--active"
                    );
                    if (activeNavMenuLabel) {
                        activeNavMenuLabel.classList.remove("--active");
                    }
                } else {
                    // Otherwise, close other menus and open the selected one
                    navBlocks.forEach((navBlock) => {
                        navBlock.style.display = "none";
                    });
                    navMenuLabels.forEach((navMenuLabel) => {
                        navMenuLabel.classList.remove("--active");
                    });

                    navMenuLabel.classList.add("--active");
                    jQuery(".m-navMenu__label.--active").next().slideToggle();
                }
            });
        });
    }

    if (countrySelector) {
        countrySelector.style.display = "none";
        countrySelector.classList.add("m-countrySelector--has-loaded");
        countrySelector.classList.remove("m-countrySelector--preload");
    }

    if (searchForm) {
        searchForm.style.display = "none";
        searchForm.classList.add("m-searchForm--has-loaded");
        searchForm.classList.remove("m-searchForm--preload");
    }

    if (mobileNavToggle) {
        mobileNavToggle.addEventListener("click", () => {
            // If the menu is open, close it
            if (mobileNavToggle.classList.contains("--open")) {
                jQuery(".o-mobileMenu").slideToggle();
                mobileNavToggle.classList.remove("--open");
            } else {
                mobileNavToggle.classList.add("--open");
                jQuery(".o-mobileMenu").slideToggle();
            }
        });
    }

    if (mobileSearchToggle) {
        mobileSearchToggle.addEventListener("click", () => {
            //If the menu is open, close it
            if (mobileSearchToggle.classList.contains("--open")) {
                jQuery(".m-searchForm").slideToggle();
                mobileSearchToggle.classList.remove("--open");
            } else {
                // Hide country selector
                countrySelector.style.display = "none";
                mobileCountryToggle.classList.remove("--open");

                mobileSearchToggle.classList.add("--open");
                jQuery(".m-searchForm").slideToggle();
            }
        });
    }

    if (mobileCountryToggle) {
        mobileCountryToggle.addEventListener("click", () => {
            const primaryNavHeight = primaryNav.offsetHeight;
            countrySelector.parentElement.style.maxHeight = `calc(100vh - ${primaryNavHeight}px)`;
            // If the menu is open, close it
            if (mobileCountryToggle.classList.contains("--open")) {
                jQuery(".m-countrySelector").slideToggle();
                mobileCountryToggle.classList.remove("--open");
            } else {
                // Hide search form
                searchForm.style.display = "none";
                mobileSearchToggle.classList.remove("--open");

                mobileCountryToggle.classList.add("--open");
                jQuery(".m-countrySelector").slideToggle();
            }
        });
    }

    if (navSearchToggle) {
        navSearchToggle.addEventListener("click", () => {
            //If the menu is open, close it
            if (navSearchToggle.classList.contains("--open")) {
                jQuery(".m-searchForm").slideToggle();
                navSearchToggle.classList.remove("--open");
            } else {
                // Hide country selector
                countrySelector.style.display = "none";
                navCountryToggle.classList.remove("--open");

                navSearchToggle.classList.add("--open");
                jQuery(".m-searchForm").slideToggle();
            }
        });
    }

    if (navCountryToggle) {
        navCountryToggle.addEventListener("click", () => {
            const primaryNavHeight = primaryNav.offsetHeight;
            countrySelector.parentElement.style.maxHeight = `calc(100vh - ${primaryNavHeight}px)`;
            //If the menu is open, close it
            if (navCountryToggle.classList.contains("--open")) {
                jQuery(".m-countrySelector").slideToggle();
                navCountryToggle.classList.remove("--open");
            } else {
                // Hide search form
                searchForm.style.display = "none";
                navSearchToggle.classList.remove("--open");

                navCountryToggle.classList.add("--open");
                jQuery(".m-countrySelector").slideToggle();
            }
        });
    }

    //Controls the opening/closing of menu blocks in the mobile menu
    const mobileMenuLabels = Array.from(
        document.querySelectorAll(".o-mobileMenu__label")
    );

    if (mobileMenuLabels.length > 0) {
        mobileMenuLabels.forEach((mobileMenuLabel) => {
            mobileMenuLabel.addEventListener("click", () => {
                let menu = mobileMenuLabel.dataset.menu;
                // If the menu is open, close it
                if (mobileMenuLabel.classList.contains("--active")) {
                    mobileMenuLabel.classList.remove("--active");
                    mobileMenuLabel
                        .querySelector(".o-mobileMenu__toggle")
                        .classList.remove("o-mobileMenu__toggle--is-clicked");
                    jQuery("#mobileMenu-" + menu).slideToggle();
                } else {
                    mobileMenuLabel.classList.add("--active");
                    mobileMenuLabel
                        .querySelector(".o-mobileMenu__toggle")
                        .classList.add("o-mobileMenu__toggle--is-clicked");
                    jQuery("#mobileMenu-" + menu).slideToggle();
                }
            });
        });
    }

    // Close menus if user clicks outside of them
    document.addEventListener("mouseup", (e) => {
        let target = e.target; // Record target div
        if (jQuery(target).parents(".o-primaryNav").length == 0) {
            // Only update classes if the element exists
            if(mobileNavToggle) {
                mobileNavToggle.classList.remove("--active");
            }
            if(mobileSearchToggle) {
                mobileSearchToggle.classList.remove("--active");
            }
            if(mobileCountryToggle) {
                navCountryToggle.classList.remove("--open");
            }
            if(mobileCountryToggle) {
                mobileCountryToggle.classList.remove("--active");
            }
            if(navMenuLabels) {
                navMenuLabels.forEach((navMenuLabel) => {
                    navMenuLabel.classList.remove("--active");
                });
            }
            if(mobileMenus) {
                mobileMenus.forEach((mobileMenu) => {
                    mobileMenu.style.display = "none";
                });
            }
            if(countrySelector) {
                countrySelector.style.display = "none";
            }
            if(searchForm) {
                searchForm.style.display = "none";
            }
            if(navBlocks) {
                navBlocks.forEach((navBlock) => {
                    navBlock.style.display = "none";
                });
            }
        }
    });
});
